import apiDirectories from '../libraries/api/directories';

class DirectoriesModel {
    constructor() {
        this.reset();
    }

    reset() {
        this.directories = {};
        this.filesByDirs = {};
    }

    get() {
        return new Promise((resolve, reject) => {
            if (Object.keys(this.directories).length > 0) {
                resolve(this.directories)
                return;
            }
            apiDirectories.get().then((apiParser) => {
                const dirs = apiParser.getAttrByTypeInData('directory', -1);

                for (const i in dirs) {
                    this.directories[dirs[i].local] = dirs[i];
                }

                resolve(this.directories);
            }).catch(reject);
        });
    }

    getFiles(dir) {
        return new Promise((resolve, reject) => {
            this.get().then((dirs) => {
                if (this.filesByDirs[dir]) {
                    resolve({
                        attributes : this.directories[this.filesByDirs[dir].local],
                        files : this.filesByDirs[dir]
                    });
                    return;
                }
                apiDirectories.getFiles(dir).then((apiParser) => {
                    const directory = apiParser.getAttrByTypeInData('directory', 1);
                    if (directory.length === 1) {
                        const files = apiParser.getRelationshipsByIdInData(directory[0].id);
                        this.filesByDirs[dir] = {
                            local : directory[0].local,
                            picture : [],
                            movie : [],
                            sub_directory : []
                        };

                        for (const i in files) {
                            this.filesByDirs[dir][files[i].type].push(apiParser.getAttrByIdInIncluded(files[i].id));
                        }

                        resolve({
                            attributes : dirs[directory[0].local],
                            files : this.filesByDirs[dir]
                        });
                        return;
                    }
                    reject();
                }).catch(reject);
            }).catch(reject);
        });
    }
}

export const directories = new DirectoriesModel();