import { Link } from "react-router-dom";
import PropTypes from 'prop-types';
import Image from '../Image';

const BreadcrumbTrail = (props) => {

    const returnHtml = [];

    returnHtml.push(<Link key={"breadcrumb-home"} to="/"><div className="breadcrumb">Home</div></Link>)
    returnHtml.push(<Link key={"breadcrumb-start"} to={"/explore/" + props.dir.local}><div className="breadcrumb">{props.dir.label}</div></Link>)
    const path = props.currentDir.split('/');

    let toPath = props.dir.local;
    for (const i in path) {
        if (i > 0 && path[i].length > 0) {
            toPath += path[i] + '/';
            const label = path[i].substring(0, 1) === '_' ? path[i].substring(1) : path[i];
            returnHtml.push(<Link key={"breadcrumb" + i} to={"/explore/" + toPath}><div className="breadcrumb">{label}</div></Link>);
        }
    }

    return <div className="breadcrumb-trail">{returnHtml}</div>;
}

BreadcrumbTrail.propTypes = {
    dir: PropTypes.object.isRequired,
    currentDir: PropTypes.string.isRequired
}

export default BreadcrumbTrail;