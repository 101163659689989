import { Link } from "react-router-dom";
import PropTypes from 'prop-types';

const Dir = (props) => {

    const label = props.label.substring(0, 1) === '_' ? props.label.substring(1) : props.label;

    return <Link to={ "/explore/" + props.local } title={props.label}><div className="grid-elem">
        <div className="logo">&#128193;</div>
        <div className="stats">
            {props.stats && props.stats.pictures ? <span className="stats">&#127748; {props.stats.pictures}</span> : ''}
            {props.stats && props.stats.movies ? <span className="stats">&#127916; {props.stats.movies}</span> : ''}
            {props.stats && props.stats.dirs ? <span className="stats">&#128193; {props.stats.dirs}</span> : ''}
        </div>
        <div className="label">{label}</div>
    </div></Link>;
}

Dir.propTypes = {
    label: PropTypes.string.isRequired,
    local: PropTypes.string.isRequired,
    stats: PropTypes.object
}

export default Dir;