import { Link } from "react-router-dom";
import PropTypes from 'prop-types';
import apiToken from '../../libraries/apiToken';

const Movie = (props) => {
    return <Link to={props.url.movie} target="get_movie" title={props.label}><div className="grid-elem">
        <div className="logo">
            &#127916;
        </div>
        <div className="label">{props.label}</div>
    </div></Link>;
}

Movie.propTypes = {
    label: PropTypes.string.isRequired
}

export default Movie;