import axios from 'axios';
import apiToken from './apiToken';
import apiParser from './api/parser';

class apiHttp {
    static withAuth = false;

    static getUrl() {
        return process.env.NODE_ENV === 'development' ? 'http://photo.local:8080/api/' : 'https://api-photo.rgrimaldi.com/api/';
    }

    static buildParamsPost(object) {
        const dataPost = new FormData();
        for (let key in object) {
            dataPost.append(key, object[key]);
        }

        return dataPost;
    }

    static getHeaders(method) {
        const headers = {
            'Content-Type' : method === 'GET' ? 'application/json' : 'application/x-www-form-urlencoded',
            'X-Requested-With': 'XMLHttpRequest'
        }
        if (this.withAuth) {
            this.token = apiToken.getToken();
            headers['Authorization'] = 'Bearer ' + this.token;
        }
        return headers;
    }

    static refreshToken() {
        return new Promise((resolve, reject) => {
            const credentials = apiToken.getRefreshToken();

            this.request('POST', 'token/refresh', {credentials}).then((apiParser) => {
                const tokens = apiParser.getAttrByTypeInData('token', 1);
                if (tokens.length === 1 && apiToken.set(tokens[0])) {
                    resolve();
                    return;
                }

                reject();
            }).catch(reject);
        });
    }

    static request(method, path, params, auth) {
        return new Promise((resolve, reject) => {
            const token = apiToken.getToken();
            if (this.withAuth && (!token || token.length === 0)) {
                reject({
                     status : 401
                });
                return;
            }

            const url = this.getUrl() + path;
            const config = {
                headers : this.getHeaders(method),
                method,
                url
            }
            if (auth) {
                config.auth = auth;
            }

            let request = null;

            if (method === 'POST') {
                if (Object.keys(params).length > 0) {
                    config.data = this.buildParamsPost(params);
                }
            } else {
                const paramsUrl = [];
                for (const key in params) {
                    paramsUrl.push(key + '=' + params[key]);
                }
                config.url = url + (paramsUrl.length > 0 ? ('?' + paramsUrl.join('&')) : '');
            }

            const successRequest = (resp) => {
                const parser = new apiParser(resp);
                resolve(parser);
            }

            const errorAuth = () => {
                reject({
                    status : 401
                });
            }

            axios.request(config).then(successRequest).catch((error) => {
                if (error.code === "ERR_NETWORK") {
                    errorAuth();
                    return;
                }
                if (path !== 'token/refresh' && !auth && error.response.status === 401) {
                    this.refreshToken().then(() => {
                        config.headers = this.getHeaders(method);
                        axios.request(config).then(successRequest).catch(errorAuth);
                    }).catch(errorAuth);
                    return;
                }

                reject(error.response);
            })
        });
    }
}

export default apiHttp;