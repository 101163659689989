import apiHttp from '../apiHttp';

class apiAccount extends apiHttp {
    static withAuth = true;

    static get() {
        return this.request(
            'GET',
            '/account/'
        );
    }
}

export default apiAccount;