import { Link } from "react-router-dom";
import PropTypes from 'prop-types';
import Image from '../Image';

const Picture = (props) => {
    return <Link to={props.url.full} title={props.label} target="_full"><div className="grid-elem">
        <div className="logo">
            <Image url={props.url.preview} />
        </div>
        <div className="label">{props.label}</div>
    </div></Link>;
}

Picture.propTypes = {
    label: PropTypes.string.isRequired,
    url: PropTypes.object
}

export default Picture;