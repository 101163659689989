import React from 'react';
import ReactDOM from 'react-dom/client';
import Photo from './Photo';
import { BrowserRouter as Router } from "react-router-dom";

if (document.location.pathname === "/home") {
    document.location.href = "https://" + document.location.hostname;
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<Router><Photo /></Router>);
