import apiHttp from '../apiHttp';

class apiDirectories extends apiHttp {
    static withAuth = true;

    static get() {
        return this.request(
            'GET',
            '/directories/'
        );
    }

    static getFiles(dir) {
        return this.request(
            'GET',
            '/directories/explore/' + dir
        );
    }
}

export default apiDirectories;