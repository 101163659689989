import { Link } from "react-router-dom";
import { isAEC } from '../libraries/constants';

const IconPhoto = (props) => {

    let size = props.size;
    const sizes = ['small', 'big'];
    if (sizes.indexOf(size) === -1) {
        size = 'small';
    }

    if (isAEC) {
        size = size === 'small' ? 50 : 150;
        return <img src="https://s.aec-ecurie.fr/images/logo_aec.jpg" height={size} />;
    }

    return <Link to="/"><div className={"photo-" + size}>
        <div className="top"></div>
        <div className="flash"></div>
        <div className="red"></div>
        <div className="decl"></div>
        <div className="objectif"></div>
    </div></Link>
}

export default IconPhoto;