class apiParser {
    constructor(resp) {
        this.data = ('data' in resp && 'data' in resp.data) ? this.parse(resp.data.data) : {};
        this.included = ('data' in resp && 'included' in resp.data) ? this.parse(resp.data.included) : {};
        this.status = resp.status ? resp.status : 400;
    }

    parse(data) {
        const returnData = {};
        for (const pos in data) {
            const current = data[pos];
            if ('type' in current && 'id' in current) {
                returnData[current.id] = current;
            }
        }

        return returnData;
    }

    getAttrByTypeInData(type, limit) {
        const returnAttrs = [];
        for (const id in this.data) {
            if (this.data[id].type === type) {
                const returnAttr = this.data[id].attributes;
                returnAttr.id = id;
                returnAttrs.push(returnAttr);
                if (returnAttrs.length === limit) {
                    return returnAttrs;
                }
            }
        }

        return returnAttrs;
    }

    getRelationshipsByIdInData(id) {
        return this.data[id] ? this.data[id].relationships : [];
    }

    getAttrByIdInIncluded(id) {
        return this.included[id] ? this.included[id].attributes : [];
    }
}

export default apiParser;