import apiHttp from '../apiHttp';

class apiAuth extends apiHttp {
    static withAuth = false;

    static auth(auth) {
        return this.request('POST', 'token/auth', [], auth);
    }

    static link(auth) {
        return this.request('POST', 'token/link', [], auth);
    }
}

export default apiAuth;