import { useState } from 'react';
import InputText from './input/text';
import InputBtn from './input/button';
import IconPhoto from './IconPhoto';
import { account } from '../models/account';

const Login = () => {
    const [error, setErrorLogin] = useState(null);
    const [loading, setLoading] = useState(false);

    const submitFormLogin = (event) => {
        event.preventDefault();
        setLoading(true);
        const formLogin = new FormData(event.target)
        account.login(formLogin.get('login'), formLogin.get('password')).catch((error) => {
            setErrorLogin('Veuillez vérifier vos identifiants')
            setLoading(false);
        });

        return false;
    };

    return <div className="panel-login">
            <IconPhoto size="big" />
            <h3>Album Photos</h3>
            {error ? <div className="alert alert-error">{error}</div> : ''}
            <form action="" method="POST" id="login" onSubmit={submitFormLogin}>
                <InputText label="Login" name="login" required={true} />
                <InputText label="Mot de passe" name="password" type="password" required={true} />
                <div className="content-input btn-input">
                    {
                        loading ? <div className="bar-loader"></div> : <InputBtn type="submit" name="btn-login" className="btn" id="btn-login" value="Connexion" />
                    }
                </div>
            </form>
        </div>;
}

export default Login;