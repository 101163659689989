import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import { account } from '../models/account';
import IconPhoto from './IconPhoto';
import InputBtn from './input/button';

const Header = (props) => {
     const logout = (event) => {
        event.preventDefault();
        account.logout();

        return false;
    };

    return <header>
        <IconPhoto size="small" />
        <div className="pseudo">{props.pseudo}
        </div>
        <div className="menu">
            <InputBtn type="button" className="screen" value="Déconnexion" onClick={logout} name="header-logout" id="header-logout" />
            <InputBtn type="button" className="mobile" value="&#10008;" onClick={logout} name="header-logout" id="header-logout" />
        </div>
    </header>;
}
Header.propTypes = {
    pseudo : PropTypes.string.isRequired
}

export default Header;