import { useState, useEffect } from 'react';
import Loader from './Loader';
import Dir from './elements/Dir';
import { directories } from '../models/directories';

const Directories = () => {
    const [loading, setLoading] = useState(true);
    const [dirs, setDirs] = useState([]);

    useEffect(() => {
        if (dirs.length === 0) {
            directories.get().then((dirs) => {
                setDirs(dirs)
                setLoading(false);
            }).catch();
        }
    });

    if (loading) {
        return <Loader />;
    }

    const dirsBlocs = [];
    let pos = 1;
    Object.values(dirs).map((elem) => {
        dirsBlocs.push(<Dir key={"dir-" + pos} {...elem} />);
        ++pos;
    })

    return <div className="my-grid">
        {dirsBlocs}
    </div>;
}

export default Directories;