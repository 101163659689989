import { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import Loader from './Loader';
import Dir from './elements/Dir';
import Picture from './elements/Picture';
import Movie from './elements/Movie';
import BreadcrumbTrail from './elements/BreadcrumbTrail';
import { directories } from '../models/directories';


const Directory = (props) => {
    const params = useParams();

    const [loading, setLoading] = useState(true);
    const [files, setFiles] = useState({});
    const [dir, setDir] = useState({});
    const [currentDir, setCurrentDir] = useState('');

    if ((params.directory + (params['*'].length > 0 ? '/' + params['*'] : '')) !== currentDir) {
        setLoading(true);
        setCurrentDir(params.directory + (params['*'].length > 0 ? '/' + params['*'] : ''));
        setFiles({});
    }

    useEffect(() => {
        if (Object.keys(files).length === 0) {
            directories.getFiles(currentDir).then((data) => {
                setFiles(data.files);
                setDir(data.attributes);
                setLoading(false);
            }).catch();
        }
    });

    if (loading) {
        return <Loader />;
    }

    const elemsBlocs = [];
    let pos = 1;
    files.sub_directory.map((elem) => {
        elemsBlocs.push(<Dir key={"dir-" + pos} {...elem} />);
        ++pos;
    });

    files.picture.map((elem) => {
        elemsBlocs.push(<Picture key={"dir-" + pos} {...elem} />);
        ++pos;
    });

    files.movie.map((elem) => {
        elemsBlocs.push(<Movie key={"dir-" + pos} {...elem} />);
        ++pos;
    });

    return <div>
        <BreadcrumbTrail dir={dir} currentDir={currentDir} />
        <div className="my-grid">
            {elemsBlocs}
        </div>
        </div>;
}

export default Directory;