import PropTypes from 'prop-types';

const InputText = (props) => {
    const id = !props.id ? 'input-' + props.name : props.id;
    const type = !props.type ? 'text' : props.type;

    return <div className="content-input label-input">
        <label>
            <span>{props.label}</span>
            <input id={id} type={type} {...props} />
        </label>
    </div>;
}

InputText.propTypes = {
    name: PropTypes.string.isRequired,
    type: PropTypes.string,
    id: PropTypes.string,
    required: PropTypes.bool,
    defaultValue: PropTypes.string,
}

export default InputText;