import { useState, useEffect } from 'react';
import { useRoutes } from "react-router-dom";
import Directories from './blocs/Directories';
import Directory from './blocs/Directory';
import Loader from './blocs/Loader';
import Login from './blocs/Login';
import Header from './blocs/Header';
import { account } from './models/account';
import { isAEC } from './libraries/constants';
import('./css/' + (isAEC ? 'poney' : 'photo') + '.css');

function Photo() {
    document.title = "Mes Photos";

    const [loading, setLoading] = useState(true);
    const [login, setLogin] = useState(false);
    const [user, setUser] = useState({});

    account.setSuccessLogin(() => {
        setLogin(false);
    });

    account.setSuccessLogout(() => {
        setLogin(true);
    });

    useEffect(() => {
        if (login !== true) {
            account.get().then((resp) => {
                setUser(resp);
                setLoading(false);
            }).catch((error) => {
                setLogin(true);
            })
        }
    });

    const routes = useRoutes([
        {
            path: "", element: <Directories />
        },
        {
            path: "/explore/:directory/*", element: <Directory />,
        }
    ]);

    const className = "content " + (isAEC ? 'poney' : '');

    if (loading && !login) {
        return <div className={className}><Loader /></div>;
    }

    if (login) {
        return <div className={className}><Login /></div>;
    }

    return <div className={className}>
        <Header  pseudo={user.pseudo} />
        {routes}
    </div>;
}

export default Photo;
