import PropTypes from 'prop-types';

function getImgUrl() {
    return process.env.NODE_ENV === 'development' ? 'http://photo.local:8080/statics/photo/images/' : 'https://sports.rgrimaldi.com/images/';
}

function Image(props) {
    if (props.image || props.url) {
        return <img src={props.url ? props.url : (getImgUrl() + props.image)} {...props} />
    }
    return '';
}

Image.propTypes = {
    image: PropTypes.string,
    url: PropTypes.string,
    alt: PropTypes.string,
    className: PropTypes.string
}

export default Image;