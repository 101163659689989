import apiAccount from '../libraries/api/account';
import apiAuth from '../libraries/api/auth';
import apiToken from '../libraries/apiToken';
import { directories } from './directories';

class accountModel {
    constructor() {
        this.successLogin = null;
        this.successlogout = null;
        this.current = {};
    }

    setSuccessLogin(fct) {
        this.successLogin = fct;
    }

    setSuccessLogout(fct) {
        this.successlogout = fct;
    }

    get() {
        return new Promise((resolve, reject) => {
            if (this.current.id && apiToken.getToken()) {
                resolve(this.current)
                return;
            }
            apiAccount.get().then((apiParser) => {
                const account = apiParser.getAttrByTypeInData('account', 1);
                if (account.length === 1) {
                    this.current = account[0];
                    resolve(this.current);
                    return;
                }
                reject();
            }).catch(reject);
        });
    }

    login(username, password) {
        return new Promise((resolve, reject) => {
            return apiAuth.auth({username, password}).then((apiParser) => {
                const tokens = apiParser.getAttrByTypeInData('token', 1);
                if (tokens.length === 1 && apiToken.set(tokens[0])) {
                    if (this.successLogin) {
                        this.successLogin();
                    } else {
                        resolve();
                    }
                    return
                }
                reject({
                    status : 401
                })
            }).catch(reject);
        });
    }

    loginByToken(token, code) {
        return new Promise((resolve, reject) => {
            return apiAuth.link({token, code}).then((apiParser) => {
                const tokens = apiParser.getAttrByTypeInData('token', 1);
                if (tokens.length === 1 && apiToken.set(tokens[0])) {
                    if (this.successLogin) {
                        this.successLogin();
                    } else {
                        resolve();
                    }
                    return
                }
                reject({
                    status : 401
                })
            }).catch(reject);
        });
    }

    logout() {
        directories.reset();
        this.current = {};
        apiToken.delete();
        this.successlogout();
    }
}

export const account = new accountModel();