class apiToken {
    static keyTokenStorage = 'photo-token'
    static keyRefreshStorage = 'photo-refresh'

    static set(tokens) {
        if (tokens.token && tokens.credentials) {
            this.setToken(tokens.token);
            this.setRefreshToken(tokens.credentials);
            return true;
        }

        return false;
    }

    static delete() {
        localStorage.removeItem(this.keyTokenStorage);
        localStorage.removeItem(this.keyRefreshStorage);
    }

    static getToken() {
        return localStorage.getItem(this.keyTokenStorage);
    }

    static setToken(token) {
        return localStorage.setItem(this.keyTokenStorage, token);
    }

    static getRefreshToken() {
        return localStorage.getItem(this.keyRefreshStorage);
    }

    static setRefreshToken(token) {
        return localStorage.setItem(this.keyRefreshStorage, token);
    }
}

export default apiToken;