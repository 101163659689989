import PropTypes from 'prop-types';

const InputBtn = (props) => {
    const id = !props.id ? 'input-' + props.name : props.id;
    const className = "btn " + props.className;

    return <button id={id} className={className} {...props}>{props.value}</button>;
}

InputBtn.propType = {
    name: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    id: PropTypes.string,
    className: PropTypes.string,
    onClick: PropTypes.func
}

export default InputBtn;